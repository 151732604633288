import React from "react";
import Carousel from "react-bootstrap/Carousel";
import shore from "../content/shore1.jpg";
import fiber from "../content/fiber.jpg"
import net from "../content/networking.png"
import ContactForm from "../components/ContactForm";

import { Col, Container, Row } from "react-bootstrap";

import {SectionWrap, Card1, Card2L} from "../components/Content";
class Home extends React.Component {
  render() {
    return (
      <div>
      
      <Section
      id="contact"
      title="Domain (website name) for sale"
      >
        <ContactForm />
        </Section>
      
      </div>
    );
  }
}
export default Home;

function HTopic(props) {
  return (
    <Carousel>
      <Carousel.Item>
        <a href={props.url}>
          <img
            className="d-block w-100 text-center"
            src={props.Img}
            alt="See our other company"
          />
        </a>

        <Carousel.Caption>
          <h3>{props.txt}</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

function Section(props) {
  return (
    <div className="section">
      <div
        className="section-content"
        id={props.id}
        style={{ margin: "50px 0 50px 0" }}
      >
        <h1 style={{ margin: "20px 0 20px 0" }} class="text-center">
          {props.title}
        </h1>
        <p style={{ margin: "20px 0 70px 0" }} class="text-center">
          {props.subTit}
        </p>
        <Container>
          <Row>{props.children}</Row>
        </Container>
      </div>
    </div>
  );
}