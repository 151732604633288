import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import {Card, Row, Col} from "react-bootstrap";

export const SectionWrap = ({background, children }) =>{
    return(
        <div id={background}>
            <div class="container" id="containerSpace">{children}</div>
        </div>
    );
} ;

export function Card1(props){
    return(
<Card >
          <Row>
            <Col md={6} sm={12}>
              <Typography component="h2" variant="h4" align='center' 
              style={{paddingTop:50,paddingBottom:20}}>
                {props.title}
              </Typography>
              
              <Typography variant="h6" align='center'
              style={{paddingBottom:20}}>
                {props.sub}
              </Typography>

              <Typography variant="subtitle1" paragraph align='center'
              style={{paddingLeft:20,paddingRight:20}}>
                {props.body}
              </Typography>
            </Col>
              <Col md={6} sm={12}>
                <CardMedia  style={{ height: "400px" }} image={props.img} title="title" />
                </Col>
          </Row>
          
        </Card>
    );
}

export function Card2L(props){
    return(
        
        <Card >
            <Row>
                <Col md={6} xs={12}
                style={{paddingRight:50}}>
                    <CardMedia  style={{ height: "400px" }} image={props.img} title="title" />
                </Col>

                <Col md={6} xs={12} id="bgGreen"
                style={{paddingLeft:50}}>
                    <Typography component="h2" variant="h4" align='center' 
                    style={{paddingTop:50,paddingBottom:20}}>
                        {props.title}
                    </Typography>
                    
                    <Typography variant="subtitle1" paragraph align='center'
                    style={{paddingLeft:20,paddingRight:20}}>
                        {props.body}
                    </Typography>
                </Col>
              
            </Row>
          
        </Card>
    );

}

export function ProdL(props){
    console.log(props.left===true)
    return(
        
            <Row className="justify-content-center" style={{padding:30}}>
                

                <Col md={props.left?{span:6,order:1}:{span:6,order:2}} sm={{span: 12,order:1}} id="prod"
                >
                    <h2>{props.title}</h2>
                    <br/>
                    <p>{props.body}</p>
                </Col>
                <Col md={props.left?{span:6,order:2}:{span:6,order:1}} sm={{span:12,order:2}} id="prod"
                >
                    <img width="100%" src={props.img} />
                </Col>
            </Row>
          
    );

}

export default SectionWrap;