import React from "react";
import SectionWrap, { ProdL } from "../components/Content";
import wifi1 from "../content/wifi4.jpg"
import wifi2 from "../content/home theater2.jpg"
import wifi3 from "../content/pc repair.jpg"
import wifi5 from "../content/wifi5.jpg"
import {Container} from "react-bootstrap";

class Res extends React.Component {
  render() {
    return (
      <div>
        <SectionWrap background="bgBlue">
          <div id="head">
            <br/> <br/>
          <h1>Residential Services</h1> <br/>
          <h4>Expertise. Intuition. Ingenuity.</h4>
          <br/> <br/>
        </div>
        </SectionWrap>
        <br/> <br/> <br/> <br/>
        <Container>
        <ProdL
        left={true}
        title="Wi-Fi Consulting"
        body="Poor wifi? Dead spots? Buffering? Dont Stress! Wi-Fi is NOT perfect. We know a thing or 2 and have the tools & knowledge to get you the PERECT Wi-Fi end to end. You COULD go to your Internet Provider, but they charge an arm & a leg for a simple wifi fix. If we can't fix it, we won't charge ya."
        img={wifi1}
        />
        <ProdL
        title="Home Theatre and Smart Devices"
        body="Ring doorbell? Security Cams? Smart Thermostat? Interested in cutting the cord (Cable TV)? Surround Sound for your TV? We obsess over this stuff & treat every install as if it were our own. We get it, the tech keeps evolving & it takes more of an effort to install convenience. Get the smart home of your dreams without the stress."
        img={wifi2}
        />
        <ProdL
        left={true}
        title="PC Repair & Upgrades"
        body="Is your PC starting to slow down? Cracked laptop screen? Don't let the salesman scam you into a new PC, get a second opinion. With a simple upgrade like an SSD or Memory install, you can give your PC up to 5 more years and save thousands. And we're a much more affordable option that whatever you'll get from Geek Squad. We even build PCs for you as well if you want a PC your style."
        img={wifi3}
        />
        <ProdL
        title="ProSumer Grade Wi-Fi & Cameras"
        body="Work From Home? Big household with everyone else doing netflix, gaming, Facetime, & school? Your Wi-Fi is more important than ever, don't settle for cheap consumer hardware. Step up to Wi-Fi by Ubuquiti, a product set that is designed for small businesses because of it's ease of use. Yet it's affordable enough for us average joes to have and RELIABLE enough for businesses to trust. We know Ubiquiti products in our sleep, let us hook you up with the good stuff. This is what we use in OUR Home"
        img={wifi5}
        />
        </Container>
      </div>
    );
  }
}
export default Res;
