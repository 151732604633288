import React, { useState } from "react";
import { Container, Row,Col ,Form, Button} from "react-bootstrap";

class ContactForm extends React.Component {
  async sendMsg(e){
    try{
    e.preventDefault();
    const {Cname,Cemail,Cnumber,Cmsg}= e.target.elements;
    console.log(Cmsg.value)
    let details={
        site:"Spark Home NJ",
        name:Cname.value,
        email:Cemail.value,
        phone:Cnumber.value,
        message:Cmsg.value
    }
    let response = await fetch("https://mdqzrv338i.execute-api.us-east-1.amazonaws.com/default/contactForm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    await response.json();
    alert("Message Sent, we'll be in touch!");
  }catch(e){
    alert("Whoops, couldn't send the message. Please try again later.");
  }
  }
  render() {
    return (
      <>
              <Col md={6} xs={12}> <br/>
            We have moved out of state and will no longer be serving the state of New Jersey. Anyone interested in creating a Smart Home contracting interested in a great website name, reach out to us!
            <h3>Thank you for your service!</h3>
            <h4>www.sparkhomesnj.com</h4>
            <br />
            <a href="info@uvgrade.com">info@uvgrade.com</a>
            <br />
            <br />
            
              </Col>
              <Col md={6} xs={12} align="left">
                <Form onSubmit={this.sendMsg}>
                <Form.Group>
                  <Form.Label>Name:</Form.Label>
                  <Form.Control id="Cname" type="text" required/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email:</Form.Label>
                  <Form.Control id="Cemail" type="email" required/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Phone:</Form.Label>
                  <Form.Control id="Cnumber" type="number" required/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Message:</Form.Label>
                  <Form.Control id="Cmsg" as="textarea" rows={3} required/>
                </Form.Group>
                <Button id="formB" type="submit">Submit</Button>
              </Form>
              </Col>
            
            <br/><br/><br/><br/>
        </>
    );
  }
}
export default ContactForm;
