import React from "react";
//import { Link, animateScroll as scroll } from "react-scroll";
import { Col, Container, Row } from "react-bootstrap";
//import sparkhomes from "../content/home/sparkhomes.png";
//import fbook from "../content/flogo.png";
//import Section from "../components/Section";
//import ContactForm from "../components/ContactForm";

class About extends React.Component {
  
    render() {
        return (
          <div class="container">
            <h1>About - Check back here soon</h1>
          </div>
        );
      }
}
export default About;


