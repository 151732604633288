import React from "react";
import Carousel from "react-bootstrap/Carousel";
import shore from "../content/shore1.jpg";
import fiber from "../content/fiber.jpg"
import net from "../content/networking.png"

import {SectionWrap, Card1, Card2L} from "../components/Content";
class Biz extends React.Component {
  render() {
    return (
      <div>
        <HTopic
          Img={shore}
          txt="Great Wi-Fi for the Garden State"
          url="https://www.sparkhomesnj.com/"
        />
      <SectionWrap background="bgGreen">
      <Card1
        title="Our Mission"
        sub="A Leader in Networking"
        body="Wi-Fi and Internet are in our DNA. Whether its a large home or a small business, we have your back when it comes to cost effective and powerful computer network solutions"
        img={fiber}
      />
      </SectionWrap>
      <SectionWrap>
        <Card2L
        title="Ubiquiti Wi-Fi Certified"
        body="We have years of experience working with Unifi Wi-Fi, routing, switching, and security camera gear from Ubiquiti. Not only are they cost effective, but easier to setup than Cisco and without any licensing costs ever."
        img={net}
        />
      </SectionWrap>
      </div>
    );
  }
}
export default Biz;

function HTopic(props) {
  return (
    <Carousel>
      <Carousel.Item>
        <a href={props.url}>
          <img
            className="d-block w-100 text-center"
            src={props.Img}
            alt="See our other company"
          />
        </a>

        <Carousel.Caption>
          <h3>{props.txt}</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}
